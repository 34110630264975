const AUTO_SIGNOUT_COUNTDOWN = 'sessionCountdown'
export const AUTO_SIGNOUT_MINS = 25

export const generateTimeToAutoSignout = (numberOfMins) => {
  if (!numberOfMins) return
  const signInTime = Date.now()
  const minsToMilliseconds = numberOfMins * 60 * 1000
  const signOutTime = signInTime + minsToMilliseconds
  return signOutTime
}

export const saveAutoSignoutCountdown = (currentTime) => {
  if (!currentTime) return
  localStorage.setItem(
    AUTO_SIGNOUT_COUNTDOWN,
    JSON.stringify({ currently: currentTime })
  )
}

export const getAutoSignoutCountdown = () => {
  const countdown = localStorage.getItem(AUTO_SIGNOUT_COUNTDOWN)
  if (!countdown) return
  const { currently } = JSON.parse(countdown)
  return currently
}

export const removeAutoSignoutCountdown = () => {
  localStorage.removeItem(AUTO_SIGNOUT_COUNTDOWN)
}
